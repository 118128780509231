import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;  // Make sure we're returning the token string itself
  };

  const [token, setTokenState] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem('token', JSON.stringify(userToken));  // Store the entire userToken object
    setTokenState(userToken.token);  // Set token in state
  };

  return {
    setToken: saveToken,
    token
  };
}
