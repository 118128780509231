import React, { createContext, useReducer } from 'react';
import { useContext } from "react";
// Initial state
const initialState = {
  userDetails: {},
  detailsForm: {},
  confirmFormDetails: {}
};

// Reducer function to handle state changes
const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_DETAILS':
      return {
        ...state,
        userDetails: action.payload
      };
    case 'SET_DETAILS_FORM':
      return {
        ...state,
        detailsForm: action.payload
      };
    case 'SET_CONFIRM_FORM':
      return {
        ...state,
        confirmFormDetails: action.payload
      };
    default:
      return state;
  }
};

// Create context
export const FormContext = createContext();

// Create a provider component
export const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};

// Create a custom hook for using the form context
export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within a FormProvider');
  }
  return context;
};