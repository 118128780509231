

// UserDetails.js (formerly UserForm.js)
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UserDetails from './DetailForm'; // Adjust if using UserDetails instead

function UserForm({ onNext,updateState, formData }) {
  // Add state for each form field
  // const [formFields, setFormFields] = useState({
  //   name: formData.name || '',
  //   partner_name: formData.partner_name || '',
  //   address: formData.address || '',
  //   partner_address: formData.partner_address || '',
  //   mobile_no: formData.mobile_no || '',
  //   mobile_no_2: formData.mobile_no_2 || '', // Fixed typo here
  //   whatsapp_no: formData.whatsapp_no || '',
  //   email: formData.email || '',
  //   partner_email: formData.partner_email || '',
  //   qualification: formData.qualification || '',
  //   school_university_name: formData.school_university_name || '',
  //   course_year: formData.course_year || '',
  //   present_occupation: formData.present_occupation || '',
  //   company_name: formData.company_name || '',
  //   din_number: formData.din_number || '',
  //   company_functional_year: formData.company_functional_year || '',
  //   office_address: formData.office_address || '',
  //   office_contact_number: formData.office_contact_number || '',
  //   reference_name_1: formData.reference_name_1 || '',
  //   reference_name_2: formData.reference_name_2 || '',
  //   reference_number_1: formData.reference_number_1 || '',
  //   reference_number_2: formData.reference_number_2 || '',
  //   company_website_name: formData.company_website_name || '',
  // });

  const [formFields, setFormFields] = useState(formData);
  const [errors, setErrors] = useState({});
  // Update state when formData changes
  useEffect(() => {
    setFormFields(prevFields => ({
      ...prevFields,
      ...formData
    }));
  }, [formData]);

  // // Handle input changes
  // const handleInputChange = (field) => (e) => {
  //   setFormFields(prevFields => ({
  //     ...prevFields,
  //     [field]: e.target.value
  //   }));
  //   updateState(formFields);
  // };

  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    const updatedFields = {
      ...formFields,
      [field]: value,
    };
    setFormFields(updatedFields);
    // Update the global state
    updateState(updatedFields);
  };
   // Required fields for the form
   // Required fields for the form
   const requiredFields = [
    { field: 'name', label: 'Name' },
    { field: 'address', label: 'Address' },
    { field: 'mobile_no', label: 'Mobile Number' },
    { field: 'email', label: 'Email Address' },
    { field: 'qualification', label: 'Qualification' },
    { field: 'school_university_name', label: 'School/University' },
    { field: 'course_year', label: 'Course/Year' },
    { field: 'present_occupation', label: 'Present Occupation' },
    { field: 'company_name', label: 'Company Name' },
    { field: 'din_number', label: 'DIN Number' },
    { field: 'company_functional_year', label: 'Company Functional Year' },
    { field: 'office_address', label: 'Office Address' },
    { field: 'office_contact_number', label: 'Office Contact Number' },
    { field: 'reference_name_1', label: 'Reference Name 1' },
    { field: 'reference_number_1', label: 'Reference Number 1' },
  ];
// Handle form submission
const handleNext = () => {
  // Check if all required fields are filled
  let hasErrors = false;
  const newErrors = {};

  requiredFields.forEach(({ field, label }) => {
    if (!formFields[field]) {
      hasErrors = true;
      newErrors[field] = `${label} is required.`;
    }
  });

  if (hasErrors) {
    setErrors(newErrors);
  } else {
    setErrors({}); // Clear all errors
    if (typeof onNext === 'function') {
      onNext(formFields);
    } else {
      console.error('onNext is not a function');
    }
  }
};
  // // Handle form submission
  // const handleNext = () => {
  //   if (typeof onNext === 'function') {
  //     onNext(formFields);
  //   } else {
  //     console.error('onNext is not a function');
  //   }
  // };

  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-12">
          <div id="msform" >
            <fieldset>
              <h2 className="fs-title">Expression of Effect for Opening a School</h2>
              <h3 className="fs-subtitle">1. Personal Information</h3>
              {/* Warning messages */}
             


              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('name')}
                value={formFields ?.name}
                name="Name"
                required
                placeholder="Name"
              />
               {errors.name && <p className="error-message"style={{ color: 'red' }}>{errors.name}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('partner_name')}
                value={formFields?.partner_name}
                name="NameTwoPartner"
                required
                placeholder="Name in case of two partners"
              />

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('address')}
                value={formFields?.address}
                required
                name="Address"
                placeholder="Address"
              />
              {errors.address && <p style={{ color: 'red' }}>{errors.address}</p>}
              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('partner_address')}
                value={formFields?.partner_address}
                name="AddressTwoPartner"
                placeholder="Address in case of two partners"
              />

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-4"
                type="number"
                onChange={handleInputChange('mobile_no')}
                value={formFields?.mobile_no}
                name="MobileNumber1"
                placeholder="1 - Mobile number"
              />
          {errors.mobile_no && <p style={{ color: 'red' }}>{errors.mobile_no}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-4"
                type="number"
                onChange={handleInputChange('mobile_no_2')}
                value={formFields?.mobile_no_2o}
                name="MobileNumber2"
                placeholder="2 - Mobile number"
              />

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-4"
                type="text"
                onChange={handleInputChange('whatsapp_no')}
                value={formFields?.whatsapp_no}
                name="WhatsAppNo"
                placeholder="WhatsApp No"
              />

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('email')}
                value={formFields?.email}
                name="EmailAddress"
                placeholder="Email Address"
              />
            {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('partner_email')}
                value={formFields?.partner_email}
                name="EmailAddressTwoPartners"
                placeholder="Email Address in case of two partners"
              />

              <h3 className="fs-subtitle">2. Education</h3>
              <h2 className="fs-title">Qualification | School/University | Course/Year of Completion</h2>

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-4"
                type="text"
                onChange={handleInputChange('qualification')}
                value={formFields?.qualification}
                name="Qualification"
                placeholder="Enter your qualification"
              />
 {errors.qualification && <p style={{ color: 'red' }}>{errors.qualification}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-4"
                type="text"
                onChange={handleInputChange('school_university_name')}
                value={formFields?.school_university_name}
                name="SchoolUniversity"
                placeholder="Enter school/university"
              />

{errors.school_university_name && <p style={{ color: 'red' }}>{errors.school_university_name}</p>}
<input
                className="col-12 col-sm-8 col-md-6 col-lg-4"
                type="text"
                onChange={handleInputChange('course_year')}
                value={formFields?.course_year}
                name="CourseYear"
                placeholder="Enter course/year of completion"
              />
 {errors.course_year && <p style={{ color: 'red' }}>{errors.course_year}</p>}

              <h3 className="fs-subtitle">3. Business / Professional Details</h3>

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('present_occupation')}
                value={formFields?.present_occupation}
                name="Occupation"
                placeholder="Enter your present occupation"
              />
 {errors.present_occupation && <p style={{ color: 'red' }}>{errors.present_occupation}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('company_name')}
                value={formFields?.company_name}
                name="CompanyName"
                placeholder="Enter company name"
              />
 {errors.company_name && <p style={{ color: 'red' }}>{errors.company_name}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('din_number')}
                value={formFields?.din_number}
                name="DINNumber"
                placeholder="Enter DIN number"
              />
 {errors.din_number && <p style={{ color: 'red' }}>{errors.din_number}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('company_functional_year')}
                value={formFields?.company_functional_year}
                name="YearsFunctional"
                placeholder="Enter number of years"
              />
               {errors.company_functional_year && <p style={{ color: 'red' }}>{errors.company_functional_year}</p>}


              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('office_address')}
                value={formFields?.office_address}
                name="OfficeAddress"
                placeholder="Enter office address"
              />
 {errors.office_address && <p style={{ color: 'red' }}>{errors.office_address}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('office_contact_number')}
                value={formFields?.office_contact_number}
                name="ContactDetails"
                placeholder="Enter contact details"
              />
 {errors.office_contact_number && <p style={{ color: 'red' }}>{errors.office_contact_number}</p>}

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('reference_name_1')}
                value={formFields?.reference_name_1}
                name="Reference1"
                placeholder="Enter reference 1"
              />
               {errors.reference_name_1 && <p style={{ color: 'red' }}>{errors.reference_name_1}</p>}

               <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('reference_name_2')}
                value={formFields?.reference_name_2}
                name="Reference2"
                placeholder="Enter reference 2"
              />

              <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('reference_number_1')}
                value={formFields?.reference_number_1}
                name="Reference2"
                placeholder="Enter reference 1 Mobile Number"
              />
               {errors.reference_number_1 && <p style={{ color: 'red' }}>{errors.reference_number_1}</p>}

               <input
                className="col-12 col-sm-8 col-md-6 col-lg-6"
                type="text"
                onChange={handleInputChange('reference_number_2')}
                value={formFields?.reference_number_2}
                name="Reference2"
                placeholder="Enter reference 2 Mobile Number"
              />


              <input
                className="col-12 col-sm-8 col-md-6 col-lg-12"
                type="text"
                onChange={handleInputChange('company_website_name')}
                value={formFields?.company_website_name}
                name="CompanyWebsite"
                placeholder="Company Website (if any)"
              />

<button type="button" className="next action-button" onClick={handleNext}>Next</button>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
}

UserForm.propTypes = {
  formData: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired
};

export default UserForm;

