

// import React, { useEffect ,useState} from 'react';
// import { useForm } from 'react-hook-form';

// export default function DetailForm({ nextStep, prevStep, formData, isConfirmationPage,updateState }) {
//   const { register, handleSubmit, setValue, getValues } = useForm({
//     defaultValues: formData,
//   });

//   useEffect(() => {
//     if (formData) {
//       Object.keys(formData).forEach((field) => {
//         setValue(field, formData[field]);
//       });
      
//     }
//   }, [formData, setValue,]);
//   const [formFields, setFormFields] = useState(formData);

//   const onSubmit = (data) => {
//     console.log("Form data on submit:", data);
//     if (typeof nextStep === 'function') {
//       nextStep(data); // Pass form data to the next step
//       updateState(formFields)
      
//     } else {
//       console.error('nextStep is not a function');
//     }
//   };

//   const handleFormChange = () => {
//     if (typeof nextStep === 'function') {
//       nextStep(getValues());
//     }
//   };

//   const getInputValue = (name) => getValues(name) || '';

//   return (
//     <div className="container-fluid">
//             <div className="row d-flex justify-content-center">
//                <div className="col-lg-12 ">
//                <div id="msform">
//                <fieldset>
//     <form onSubmit={handleSubmit(onSubmit)} id="msform" className='msform'>
//       {/* Business Nature Section */}
//       <h2 className="fs-title">4. Nature of Business</h2>
//       <table className="table">
//         <thead>
//           <tr>
//             <th className="fs-title" scope="col">Name of Business</th>
//             <th className="fs-title" scope="col">Annual Turnover</th>
//             <th className="fs-title" scope="col">Nature of Business / Trade</th>
//           </tr>
//         </thead>
//         <tbody>
//           {[1, 2, 3].map(i => (
//             <tr key={i}>
//               <td>
//                 <input
//                   type="text"
//                   {...register(`name_of_business_${i}`)}
//                   placeholder={`Enter business name ${i}`}
//                   className="form-control inp"
//                   disabled={isConfirmationPage}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   {...register(`annual_turnover_${i}`)}
//                   placeholder={`Enter annual turnover ${i}`}
//                   className="form-control inp"
//                   disabled={isConfirmationPage}
//                 />
//               </td>
//               <td>
//                 <input
//                   type="text"
//                   {...register(`nature_of_business_${i}`)}
//                   placeholder={`Nature of Business / Trade ${i}`}
//                   className="form-control inp"
//                   disabled={isConfirmationPage}
//                 />
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Registered/Trust Section */}
//       <div className='row'>
//         <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
//           <label className='left-text'>
//             Do you have any Registered/Trust/Under Section 8 Company? If yes, mention the name and details and profession of members
//           </label>
//         {/* </div> */}

//         {/* <div className='col-12 col-sm-12 col-md-12 col-lg-8'> */}
//           <input
//             type="radio"
//             {...register('registered_firms_check')}
//             value="yes"
//             disabled={isConfirmationPage}
//           />
//           <label>Yes</label>

//           <input
//             type="radio"
//             {...register('registered_firms_check')}
//             value="no"
//             disabled={isConfirmationPage}
//           />
//           <label>No</label>

//           {getValues('registered_firms_check') === 'yes' && (
//             <input
//               type="text"
//               {...register('registered_firms_details')}
//               placeholder="Enter Nature of Business / Trade"
//               className="form-control"
//               disabled={isConfirmationPage}
//             />
//           )}
//         </div>
//       </div>

//       {/* Additional Information Section */}
//       <div className='row'>
//         <div className='col-12 col-sm-8 col-md-6 col-lg-12'>
         
//           <textarea
//             className='mytextarea'
//             {...register('additional_information')}
//             placeholder="Any other information:"
//             disabled={isConfirmationPage}
//           />
//         </div>
//         <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//         <lable className='left-text'>Please not we will also require : </lable>
        
//             <select
//               className='landtype'
//               {...register('tax_return_type')}
//               disabled={isConfirmationPage}
//             >
//               <option value="">Select Tax Reutn </option>
//               <option value="taxType1">Group Turn Over</option>
//               <option value="taxType2">Balance Sheet Of the Company</option>
              
//               <option value="taxType3">Group and Personal Tax Return of main applicant</option>
//             </select>
        
      
       
//           <label>
//             Balance Sheet (File Upload):
//             <input
//               className='col-12 col-sm-8 col-md-6 col-lg-6 myfileupload'
//               type="file"
//               {...register('tax_return_file')}
//               disabled={isConfirmationPage}
//             />
//           </label>
//           </div>
      
//       </div>

//       {/* File Upload and Land Type Section */}
//       <div className='row'>
//       <div className='col-12 col-sm-8 col-md-6 col-lg-12'>
//           <label className='left-text'>Land Type:
//             <select
//               className='landtype'
//               {...register('land_type')}
//               disabled={isConfirmationPage}
//             >
//               <option value="">Select Land Type</option>
//               <option value="landType1">Land Type 1</option>
//               <option value="landType2">Land Type 2</option>
//             </select>
//             </label> 
//         </div>
     
//       </div>

//       {/* Involvement, Location, Land Details Section */}
//       <div className='row'>
//       <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//           <textarea
//             className='mytextarea'
//             {...register('activity_to_proposed_school')}
//             placeholder="Involvement Details:"
//             disabled={isConfirmationPage}
//           />
//         </div>
        
//         <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//           <textarea
//             className='mytextarea'
//             {...register('project_location')}
//             placeholder="Location/Cities Identified for the project:"
//             disabled={isConfirmationPage}
//           />
//         </div>
//       </div>

//       <div className='row'>
//       <div className='col-12 col-sm-8 col-md-6 col-lg-12'>
//           <textarea
//             className='inp'
//             type="text"
//             {...register('deatils_of_land_possession')}
//             placeholder="Details of the land in your possession,including area of the land with address and kindlyalso include site plan :"
//             disabled={isConfirmationPage}
//           />
//         </div>
      
//       </div>

//       {/* Year of Establishment and Residential Colonies Section */}
//       <div className='row'>
//       <div className='col-12 col-sm-8 col-md-6 col-lg-2'>
//       <label className='left-text'>What will be the source of funds for starting & running the school Operations:
//             <select
//               className='landtype'
//               {...register('fund_type')}
//               disabled={isConfirmationPage}
//             >
//               <option value="">Select Fund Type</option>
//               <option value="self_fund">Self Fund</option>
//               <option value="invester_fund">Invester Fund</option>
//               <option value="bank_loan">Bank Loan</option>
//             </select>
//             </label> 
//         </div>
//         </div>
//         <div className='row'>
//       <div className='col-12 col-sm-8 col-md-6 col-lg-12'>
//           <input
//             className='inp'
//             type="text"
//             {...register('school_academic_year')}
//             placeholder="By when do you forease to the school academic session (year):"
//             disabled={isConfirmationPage}
//           />
//         </div>
//         </div>
        
    

//       {/* Prevalent Board and Convicted Section */}
     

//       {/* Navigation Buttons */}
//       <div className='row'>
//         <div className='col-12 col-sm-12 col-md-12 col-lg-12'>
//           <button className='previous action-button-previous' type="button" onClick={prevStep}>Previous</button>
//           {!isConfirmationPage && (
//             <button className='next action-button' type="submit">Next</button>
//           )}
//         </div>
//       </div>
//     </form>
//     </fieldset>
//     </div>
//     </div>
//     </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function DetailForm({ nextStep, prevStep, formData, updateState }) {
  // Initialize local state with formData
  const [formFields, setFormFields] = useState(formData);

  // Update local state when formData changes
  useEffect(() => {
    setFormFields((prevFields) => ({
      ...prevFields,
      ...formData,
    }));
  }, [formData]);

  // Handle input changes and update global state
  const handleInputChange = (field) => (e) => {
    const value = e.target.value;
    const updatedFields = {
      ...formFields,
      [field]: value,
    };
    setFormFields(updatedFields);
    // Update the global state
    updateState(updatedFields);
  };

  
  // Handle file input changes
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      setFormFields((prevFields) => ({
        ...prevFields,
        tax_return_file: fileName, // Save only the file name
      }));
      // If needed, handle file upload here
      // Example: uploadFile(file);
    }
  };

  const handleNext = () => {
    if (typeof nextStep === 'function') {
      nextStep(formFields);
    } else {
      console.error('nextStep is not a function');
    }
  };
  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-12">
          <div id="msform">
            <fieldset>
              <h2 className="fs-title">4. Nature of Business</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th className="fs-title" scope="col">Name of Business</th>
                    <th className="fs-title" scope="col">Annual Turnover</th>
                    <th className="fs-title" scope="col">Nature of Business / Trade</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3].map((i) => (
                    <tr key={i}>
                      <td>
                        <input
                          type="text"
                          value={formFields[`name_of_business_${i}`] || ''}
                          onChange={handleInputChange(`name_of_business_${i}`)}
                          placeholder={`Enter business name ${i}`}
                          className="form-control inp"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={formFields[`annual_turnover_${i}`] || ''}
                          onChange={handleInputChange(`annual_turnover_${i}`)}
                          placeholder={`Enter annual turnover ${i}`}
                          className="form-control inp"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={formFields[`nature_of_business_${i}`] || ''}
                          onChange={handleInputChange(`nature_of_business_${i}`)}
                          placeholder={`Nature of Business / Trade ${i}`}
                          className="form-control inp"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* Registered/Trust Section */}
              <div className='row'>
                <div className='col-6'>
                  <label className='left-text'>
                    Do you have any Registered/Trust/Under Section 8 Company? If yes, mention the name and details and profession of members
                  </label>
                  <div>
                    <input
                      type="radio"
                      name="registered_firms_check"
                      value="yes"
                      checked={formFields['registered_firms_check'] === 'yes'}
                      onChange={handleInputChange('registered_firms_check')}
                    />
                    <label>Yes</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="registered_firms_check"
                      value="no"
                      checked={formFields['registered_firms_check'] === 'no'}
                      onChange={handleInputChange('registered_firms_check')}
                    />
                    <label>No</label>
                  </div>

                  {formFields['registered_firms_check'] === 'yes' && (
                    <input
                      type="text"
                      value={formFields['registered_firms_details'] || ''}
                      onChange={handleInputChange('registered_firms_details')}
                      placeholder="Enter details of the registered firm"
                      className="form-control"
                    />
                  )}
                </div>
              

              {/* Additional Information Section */}
            
                <div className='col-12'>
                  <textarea
                    className='mytextarea'
                    value={formFields['additional_information'] || ''}
                    onChange={handleInputChange('additional_information')}
                    placeholder="Any other information:"
                  />
                </div>
              </div>

              {/* File Upload and Tax Return Section */}
              <div className='row'>
                <div className='col-4'>
                  <label className='left-text'>Please note we will also require:
                  <select
                    className='landtype'
                    value={formFields['tax_return_type'] || ''}
                    onChange={handleInputChange('tax_return_type')}
                  >
                    <option value="">Select Tax Return</option>
                    <option value="group_turnover">Group Turn Over</option>
                    <option value="balance_sheet">Balance Sheet Of the Company</option>
                    <option value="personal_tax_return">Group and Personal Tax Return of main applicant</option>
                  </select>
                  </label>
                  </div>
                  <div className='col-4'>
                  <label className='left-text'>Please Select File:
                  <input
                    type="file"
                    className='form-control'
                    onChange={handleFileChange}
                    placeholder="Upload Balance Sheet (File Upload)"
                  /></label>
                </div>
              

              {/* Land Type and Involvement Section */}
             
                <div className='col-4'>
                  <label className='left-text'>Land Type:
                  <select
                    className='landtype'
                    value={formFields['land_type'] || ''}
                    onChange={handleInputChange('land_type')}
                  >
                    <option value="">Select Land Type</option>
                    <option value="landType1">Land Type 1</option>
                    <option value="landType2">Land Type 2</option>
                  </select>
                  </label>
                </div>

                <div className='col-6'>
                  <textarea
                    className='mytextarea'
                    value={formFields['activity_to_proposed_school'] || ''}
                    onChange={handleInputChange('activity_to_proposed_school')}
                    placeholder="Involvement Details:"
                  />
                </div>
              
              {/* Location and Land Details Section */}
             
                <div className='col-6'>
                  <textarea
                    className='mytextarea'
                    value={formFields['project_location'] || ''}
                    onChange={handleInputChange('project_location')}
                    placeholder="Location/Cities Identified for the project:"
                  />
                </div>
                <div className='row'>
                <div className='col-6'>
                  <textarea
                    className='mytextarea'
                    value={formFields['deatils_of_land_possession'] || ''}
                    onChange={handleInputChange('deatils_of_land_possession')}
                    placeholder="Details of the land in your possession, including area of the land with address:"
                  />
                </div>
                <div className='col-6'>
                  <input
                    className='inp'
                    type="text"
                    value={formFields['school_academic_year'] || ''}
                    onChange={handleInputChange('school_academic_year')}
                    placeholder="By when do you foresee the school academic session (year):"
                  />
                </div>
             

              {/* Year of Establishment and Fund Source Section */}
              </div>

              {/* Additional Sections */}
              <div className='row'>
             
                <div className='col-4'>
                  <label className='left-text'>Source of funds for starting & running the school Operations:</label>
                  <select
                    className='landtype'
                    value={formFields['fund_type'] || ''}
                    onChange={handleInputChange('fund_type')}
                  >
                    <option value="">Select Fund Type</option>
                    <option value="self_fund">Self Fund</option>
                    <option value="invester_fund">Investor Fund</option>
                    <option value="bank_loan">Bank Loan</option>
                  </select>
                </div>
                
            
                <div className='col-4'>
                <label className='left-text'>Additional plans for school development:</label>
                  <textarea
                    className='mytextarea'
                    value={formFields['additional_plans'] || ''}
                    onChange={handleInputChange('additional_plans')}
                    placeholder="Additional plans for school development:"
                  />
                </div>
                <div className='col-4'>
                <label className='left-text'>Proposed marketing strategy for the school:</label>
                  <textarea
                    className='mytextarea'
                    value={formFields['marketing_strategy'] || ''}
                    onChange={handleInputChange('marketing_strategy')}
                    placeholder="Proposed marketing strategy for the school:"
                  />
                </div>
                </div>
                
              </div>


              {/* Add other fields similar to UserForm */}
              <button className='previous action-button-previous' type="button" onClick={prevStep}>Previous</button>
              <button className='next action-button' type="button" onClick={handleNext}>Next</button>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
}

DetailForm.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};
