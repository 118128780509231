import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Preferences from './components/Preferences';
import Sidemenu from './components/side-menu/SideMenu';
import Form from './Forms/Form';
import Login from './Auth/Login';  // Import the Login component
import useToken from './constant/AuthToken';
import { FormProvider } from './Forms/components/FormContext'; // Import the FormProvider

function App() {
  const { token, setToken } = useToken();  // Using the custom useToken hook

  return (
    <div className="wrapper">
      <div className="logocount">
        <div>
          <div>
          </div>
          <div>
            <img className="formlogo" src={'https://www.jaipuriaschools.com/images/logo.png'} alt="logo" />
          </div>
        </div>
        <FormProvider>  {/* Wrap Form with FormProvider */}

        <Router>
          <Routes>
            {/* Login route */}
            <Route path="/login" element={<Login setToken={setToken} />} />

            {/* Form always shown at the root */}
            <Route
              path="/"
              element={
                  <Form />
              }
            />
            {/* <Route path="/thankyou" element={<Thankyou />} /> */}

            {/* Protected routes: only accessible if token exists */}
            {token ? (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/preferences" element={<Preferences />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" />} />  // Redirect to login if no token
            )}
          </Routes>
        </Router>
        </FormProvider>

      </div>
    </div>
  );
}

export default App;
