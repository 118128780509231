import React from 'react';

export default function Dashboard() {
  return(
  <div>
    <h2>Dashboard</h2>
    <div style={{"overflowX":"auto"}} className='details'>
    <table className='dashboard-table'>
        <tr className='info-data'>
          <th>Name</th>
          <th>Partner Name</th>
          <th>Address</th>
         <th>Partner Address</th>
         <th>Mobile No</th>
         <th>Whatsapp No.</th>
         <th>Email</th>
         <th>Partner Email</th>
         <th>Action</th>
         {/* <th>Qualification</th>
         <th>School University Name</th>
         <th>Course Year</th>
         <th>Present Occupation</th>
         <th>DIN Number</th>
         <th>Company Name</th>
         <th>Company Functional Year</th>
         <th>Office Address</th>
         <th>Office Contact Number</th>
         <th>Reference Name 1</th>
          <th>Reference number 1</th>
         <th>Reference name 2</th>
         <th>Reference name 2</th>
         <th>Company Website Name</th>
          <th>Name of Business 1</th>
         <th>Name of Business 2</th>
         <th>Name of Business 3</th>
         <th>Nature of Business 1</th>
          <th>Nature of Business 2</th>
         <th>Nature of Business 3</th>
         <th>Annual Turnover 1</th>
         <th>Annual Turnover 2</th>
          <th>Annual Turnover 3</th>
         <th>Registered Firms Check</th>
         <th>Registered Firms Details</th>
         <th>Additional Information</th>
          <th>Tax Return Type</th>
         <th>Tax Return File</th>
         <th>Land Type</th>
         <th>Activity to Proposed School</th>
          <th>Project Location</th>
         <th>Fund Type</th>
         <th>School Academic Year</th>
         <th>Major Location School Name 1</th>
         <th>Major Location School Name 2</th>
         <th>Major Location School Name 3</th>
          <th>Major Location Establishment Year 1</th>
         <th>Major Location Establishment Year 2</th>
         <th>Major Location Establishment Year 3</th>
         <th>Major Location no of Students 1</th>
         <th>Major Location no of Students 2</th>
         <th>Major Location no of Students 3</th>
          <th>Major Location Monthly Fee 1</th>
         <th>Major Location Monthly Fee 2</th>
         <th>Major Location Monthly Fee 3</th>
         <th>Major Location Area of Land 1</th>
          <th>Major Location Area of Land 2</th>
         <th>Major Location Area of Land 3</th>

         <th>Residential Colonies</th>
         <th>Facilities Availability</th>
          <th>School Board Type</th>
         <th>Crime Inforamtion Type</th>

         <th>Crime Information Details</th>
         <th>Pending Court Case Type</th>
          <th>Pending Court Case Details</th>
         <th>Land Possession Type</th>
         <th>Land Possession Details</th> */}

       


        </tr>
          
        <tr>
           <td>Vaibhav Singh</td>
           <td>xyz</td>
           <td>Ghaziabad</td>
           <td>Noida</td>
           <td>5453454</td>
           <td>6555477654</td>
           <td>xyz@gmail.com</td>
           <td>tuv@gmail.com</td>
           <td>
           <a href="/" className="btn-color">
           <button className="view-btn">View</button>
            </a>
            </td>

          </tr>

    </table>

    </div>
    </div>
  );
}