
import React, { useState } from 'react';
import UserForm from './components/UserForm';
import DetailForm from './components/DetailForm';
import Confirm from './components/Confirm';
import { useForm } from './components/FormContext'; // Import the useForm hook

export default function Form() {
  // Step state for navigation
  const [currentStep, setCurrentStep] = useState(1);
  
  // Access global form state and dispatch
  const { state, dispatch } = useForm();

  // Handler for moving to the next step
  // Handler for moving to the next step
  const handleNext = (data) => {
    // if (currentStep === 1) {
    //   dispatch({ type: 'SET_USER_DETAILS', payload: data });
    // } else if (currentStep === 2) {
    //   dispatch({ type: 'SET_DETAILS_FORM', payload: data });
    // }else if(currentStep === 3){
    //   dispatch({ type: 'SET_CONFIRM_FORM', payload: data });
    // }
    setCurrentStep(currentStep + 1);
  };
  const handleState = (data) => {
    if (currentStep === 1) {
      dispatch({ type: 'SET_USER_DETAILS', payload: data });
    } else if (currentStep === 2) {
      dispatch({ type: 'SET_DETAILS_FORM', payload: data });
    }else if(currentStep === 3){
      dispatch({ type: 'SET_CONFIRM_FORM', payload: data });
    }
   
  };

  // Handler for moving to the previous step
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  
  // Handler for form submission
  const handleSubmit = async () => {
    // Combine all form data from the state
    const completeFormData = {
      ...state.userDetails,
      ...state.detailsForm,
      ...state.confirmFormDetails,
    };

    console.log('Complete form data before submission:', completeFormData);

    try {
      const response = await fetch('https://testing.sttar.in/api/expressions_interest', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(completeFormData),
      });

      if (!response.ok) {
        const errorResult = await response.json();
        throw new Error(`Error: ${errorResult.message || 'Unknown error occurred'}`);
      }

      const result = await response.json();
      alert('Form submitted successfully');
      window.location.href = '/thankyou';
    } catch (error) {
      console.error('Error submitting form:', error);
      alert(`An error occurred while submitting the form: ${error.message}`);
    }
  };

  return (
    <div>
      {currentStep === 1 && (
        <UserForm 
          formData={state.userDetails}
          updateState={handleState} 
          onNext={handleNext} 
        />
      )}
      {currentStep === 2 && (
        <DetailForm 
          formData={state.detailsForm} 
          updateState={handleState}
          nextStep={handleNext} 
          prevStep={handlePrevious} 
        />
      )}
      {currentStep === 3 && (
        <Confirm 
          formData={state.confirmFormDetails}
          nextStep={handleNext}
          updateState={handleState}
          nextHook={handleSubmit}  
          prevHook={handlePrevious} 
        />
      )}
    </div>
  );
}

