import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ReCaptcha from './ReCaptcha';
export default function Confirm({ nextStep, nextHook = () => {}, prevHook = () => {},updateState, formData ,}) {
  const { register, setValue, getValues } = useForm({
    defaultValues: formData,
  });
  
  // const [formFields, setFormFields] = useState({
  //   major_location_school_name_1: formData.major_location_school_name_1 || '',
  //   major_location_establishment_year_1: formData.major_location_establishment_year_1 || '',
  //   major_location_no_of_students_1: formData.major_location_no_of_students_1 || '',
  //   major_location_monthly_fee_1: formData.major_location_monthly_fee_1 || '',
  //   major_location_area_of_land_1: formData.major_location_area_of_land_1 || '',
  //   major_location_school_name_2: formData.major_location_school_name_2 || '',
  //   major_location_establishment_year_2: formData.major_location_establishment_year_2 || '',
  //   major_location_no_of_students_2: formData.major_location_no_of_students_2 || '',
  //   major_location_monthly_fee_2: formData.major_location_monthly_fee_2 || '',
  //   major_location_area_of_land_2: formData.major_location_area_of_land_2 || '',
  //   major_location_school_name_3: formData.major_location_school_name_3 || '',
  //   major_location_establishment_year_3: formData.major_location_establishment_year_3 || '',
  //   major_location_no_of_students_3: formData.major_location_no_of_students_3 || '',
  //   major_location_monthly_fee_3: formData.major_location_monthly_fee_3 || '',
  //   major_location_area_of_land_3: formData.major_location_area_of_land_3 || '',
  //   residential_colonies: formData.residential_colonies || '',
  //   facilities_availability: formData.facilities_availability || '',
  //   success_reason_of_school: formData.success_reason_of_school || '',
  //   school_board_type: formData.school_board_type || '',
  //   crime_information_type: formData.crime_information_type || '',
  //   crime_information_details: formData.crime_information_details || '',
  //   pending_court_case_type: formData.pending_court_case_type || '',
  //   pending_court_case_details: formData.pending_court_case_details || '',
  //   land_possession_type: formData.land_possession_type || '',
  //   land_possession_details: formData.land_possession_details || ''
  // });
//  const [formFields, setFormFields] = useState(formData);
const [formFields, setFormFields] = useState({
  major_location_school_name_1: formData?.major_location_school_name_1 ?? '',
  major_location_establishment_year_1: formData?.major_location_establishment_year_1 ?? '',
  major_location_no_of_students_1: formData?.major_location_no_of_students_1 ?? '',
  major_location_monthly_fee_1: formData?.major_location_monthly_fee_1 ?? '',
  major_location_area_of_land_1: formData?.major_location_area_of_land_1 ?? '',
  major_location_school_name_2: formData?.major_location_school_name_2 ?? '',
  major_location_establishment_year_2: formData?.major_location_establishment_year_2 ?? '',
  major_location_no_of_students_2: formData?.major_location_no_of_students_2 ?? '',
  major_location_monthly_fee_2: formData?.major_location_monthly_fee_2 ?? '',
  major_location_area_of_land_2: formData?.major_location_area_of_land_2 ?? '',
  major_location_school_name_3: formData?.major_location_school_name_3 ?? '',
  major_location_establishment_year_3: formData?.major_location_establishment_year_3 ?? '',
  major_location_no_of_students_3: formData?.major_location_no_of_students_3 ?? '',
  major_location_monthly_fee_3: formData?.major_location_monthly_fee_3 ?? '',
  major_location_area_of_land_3: formData?.major_location_area_of_land_3 ?? '',
  residential_colonies: formData?.residential_colonies ?? '',
  facilities_availability: formData?.facilities_availability ?? '',
  success_reason_of_school: formData?.success_reason_of_school ?? '',
  school_board_type: formData?.school_board_type ?? '',
  crime_information_type: formData?.crime_information_type ?? '',
  crime_information_details: formData?.crime_information_details ?? '',
  pending_court_case_type: formData?.pending_court_case_type ?? '',
  pending_court_case_details: formData?.pending_court_case_details ?? '',
  land_possession_type: formData?.land_possession_type ?? '',
  land_possession_details: formData?.land_possession_details ?? ''
});
  // useEffect(() => {
  //   setFormFields((prevFields) => ({
  //     ...prevFields,
  //     ...formData
  //   }));
  // }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("Name :",name, "value :",value);
    console.log("Form fields -",formFields);
    setFormFields((prevData) => ({
      ...prevData,
      [name]: value
    }));
    updateState(formFields);

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form fields submit - ",formFields);
   //nextStep(formFields);
    //console.log("Form fields after next step ",formFields);
    nextHook();
  };

  const handlePrevious = () => {
    prevHook(formFields);
  };

  return (
    
      <div className="container-fluid" id='msform'>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <div className='row'>
              <h2 className="fs-title">Other Major School</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th className="fs-title" scope="col">School Name</th>
                    <th className="fs-title" scope="col">Establishment Year</th>
                    <th className="fs-title" scope="col">No. of Students</th>
                    <th className="fs-title" scope="col">Monthly Fee (INR)</th>
                    <th className="fs-title" scope="col">Area of Land (In Acres)</th>
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3].map(i => (
                    <tr key={i}>
                      <td>
                        <input
                          type="text"
                          name={`major_location_school_name_${i}`}
                          value={formFields[`major_location_school_name_${i}`] || ''}
                          onChange={handleChange}
                          placeholder={`School Name ${i}`}
                          className="form-control inp"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`major_location_establishment_year_${i}`}
                          value={formFields[`major_location_establishment_year_${i}`] || ''}
                          onChange={handleChange}
                          placeholder={`Establishment Year ${i}`}
                          className="form-control inp"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`major_location_no_of_students_${i}`}
                          value={formFields[`major_location_no_of_students_${i}`] || ''}
                          onChange={handleChange}
                          placeholder={`No Of students ${i}`}
                          className="form-control inp"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`major_location_monthly_fee_${i}`}
                          value={formFields[`major_location_monthly_fee_${i}`] || ''}
                          onChange={handleChange}
                          placeholder={`Monthly Fee ${i}`}
                          className="form-control inp"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name={`major_location_area_of_land_${i}`}
                          value={formFields[`major_location_area_of_land_${i}`] || ''}
                          onChange={handleChange}
                          placeholder={`Area of Land ${i}`}
                          className="form-control inp"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
  
            <div className='row'>
              <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
                <textarea
                  name="residential_colonies"
                  value={formFields.residential_colonies || ''}
                  onChange={handleChange}
                  placeholder="Residential Colonies:"
                  className="form-control"
                />
              </div>
              <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
                <textarea
                  className='mytextarea form-control'
                  name="facilities_availability"
                  value={formFields.facilities_availability || ''}
                  onChange={handleChange}
                  placeholder="Facilities Availability:"
                />
              </div>
            </div>
  
            <div className='row'>
              <div className='col-12 col-sm-8 col-md-6 col-lg-12'>
                <textarea
                  name="success_reason_of_school"
                  value={formFields.success_reason_of_school || ''}
                  onChange={handleChange}
                  placeholder="What do you think are the reasons for the success of a school in your area?"
                  className="form-control"
                />
              </div>
            </div>
  
            <div className='row'>
              <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
                <label className='left-text'>
                  Which School Board is most prevalent in the area?:
                  <select
                    name="school_board_type"
                    value={formFields.school_board_type || ''}
                    onChange={handleChange}
                    className="landtype"
                  >
                    <option value="">Select School Board Type</option>
                    <option value="CBSE">CBSE</option>
                    <option value="ICSE">ICSE</option>
                    <option value="IB">IB</option>
                  </select>
                </label>
              </div>
            </div>
  
            <div className='row'>
              <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
                <div className="radio-count">
                  <label>
                    Have you ever been convicted of any crime involving actions contrary to the community standard of justice, honesty, or good morals?
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="crime_information_type"
                          value="yes"
                          checked={formFields.crime_information_type === "yes"}
                          onChange={handleChange}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="crime_information_type"
                          value="no"
                          checked={formFields.crime_information_type === "no"}
                          onChange={handleChange}
                        />
                        No
                      </label>
                    </div>
                  </label>
                </div>
  
                {formFields.crime_information_type === "yes" && (
                  <div className="radio-count">
                    <label>
                      If yes, please state nature and status:
                      <textarea
                        className='inp'
                        name="crime_information_details"
                        value={formFields.crime_information_details || ''}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                )}
              </div>
            </div>
  
            <div className="radio-count">
              <label>
                Is there any pending lawsuit, whether civil or criminal, of which you are accused?
                <div>
                  <label>
                    <input 
                      type="radio"
                      name="pending_court_case_type"
                      value="yes"
                      checked={formFields.pending_court_case_type === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input 
                      type="radio"
                      name="pending_court_case_type"
                      value="no"
                      checked={formFields.pending_court_case_type === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
              </label>
  
              {formFields.pending_court_case_type === "yes" && (
                <label>
                  Please provide details of the pending court case:
                  <textarea
                    className='inp'
                    name="pending_court_case_details"
                    value={formFields.pending_court_case_details || ''}
                    onChange={handleChange}
                  />
                </label>
              )}
            </div>
  
            <div className="radio-count">
              <label>
                Is there any land dispute or any legal complications in the possession of the school land?
                <div>
                  <label>
                    <input 
                      type="radio"
                      name="land_possession_type"
                      value="yes"
                      checked={formFields.land_possession_type === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input 
                      type="radio"
                      name="land_possession_type"
                      value="no"
                      checked={formFields.land_possession_type === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
              </label>
  
              {formFields.land_possession_type === "yes" && (
                <label>
                  If yes, please provide details:
                  <textarea
                    className='inp'
                    name="land_possession_details"
                    value={formFields.land_possession_details || ''}
                    onChange={handleChange}
                  />
                </label>
              )}
            </div>
            <div className="mt-4 text-center">
                        <ReCaptcha siteKey="6LdWE0MqAAAAAPEFH02nZte7cGR3pw3GHufoIhU0"  />
                    </div>
  
            <button className='previous action-button-previous'
              type="button"
              onClick={handlePrevious}
            >
              Previous
            </button>
  
            <button className='next action-button'
              type="submit"
            >
              Submit
            </button>
          </fieldset>
        </form>
      </div>
    );
  }

// import React, { useEffect } from 'react';
// import { useForm } from 'react-hook-form';
// import ReCaptcha from './ReCaptcha';

// export default function Confirm({ nextHook = () => {}, prevHook = () => {}, formData }) {
//   const { register, handleSubmit, setValue, getValues } = useForm({
//     defaultValues: formData,
//   });

//   // Sync formData with the form fields
//   useEffect(() => {
//     if (formData) {
//       Object.keys(formData).forEach((key) => {
//         setValue(key, formData[key]);
//       });
//     }
//   }, [formData, setValue]);

//   // Handle form submission
//   const onSubmit = (data) => {
//     console.log("Form fields submit -", data);
//     nextHook(data); // Pass data to the next hook
//   };

//   return (
//     <div className="container-fluid" id='msform'>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <fieldset>
//           <div className='row'>
//             <h2 className="fs-title">Other Major School</h2>
//             <table className="table">
//               <thead>
//                 <tr>
//                   <th className="fs-title" scope="col">School Name</th>
//                   <th className="fs-title" scope="col">Establishment Year</th>
//                   <th className="fs-title" scope="col">No. of Students</th>
//                   <th className="fs-title" scope="col">Monthly Fee (INR)</th>
//                   <th className="fs-title" scope="col">Area of Land (In Acres)</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {[1, 2, 3].map(i => (
//                   <tr key={i}>
//                     <td>
//                       <input
//                         type="text"
//                         {...register(`major_location_school_name_${i}`)}
//                         placeholder={`School Name ${i}`}
//                         className="form-control inp"
//                       />
//                     </td>
//                     <td>
//                       <input
//                         type="text"
//                         {...register(`major_location_establishment_year_${i}`)}
//                         placeholder={`Establishment Year ${i}`}
//                         className="form-control inp"
//                       />
//                     </td>
//                     <td>
//                       <input
//                         type="text"
//                         {...register(`major_location_no_of_students_${i}`)}
//                         placeholder={`No Of students ${i}`}
//                         className="form-control inp"
//                       />
//                     </td>
//                     <td>
//                       <input
//                         type="text"
//                         {...register(`major_location_monthly_fee_${i}`)}
//                         placeholder={`Monthly Fee ${i}`}
//                         className="form-control inp"
//                       />
//                     </td>
//                     <td>
//                       <input
//                         type="text"
//                         {...register(`major_location_area_of_land_${i}`)}
//                         placeholder={`Area of Land ${i}`}
//                         className="form-control inp"
//                       />
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           <div className='row'>
//             <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//               <textarea
//                 {...register("residential_colonies")}
//                 placeholder="Residential Colonies:"
//                 className="form-control"
//               />
//             </div>
//             <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//               <textarea
//                 className='mytextarea form-control'
//                 {...register("facilities_availability")}
//                 placeholder="Facilities Availability:"
//               />
//             </div>
//           </div>

//           <div className='row'>
//             <div className='col-12 col-sm-8 col-md-6 col-lg-12'>
//               <textarea
//                 {...register("success_reason_of_school")}
//                 placeholder="What do you think are the reasons for the success of a school in your area?"
//                 className="form-control"
//               />
//             </div>
//           </div>

//           <div className='row'>
//             <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//               <label className='left-text'>
//                 Which School Board is most prevalent in the area?:
//                 <select
//                   {...register("school_board_type")}
//                   className="landtype"
//                 >
//                   <option value="">Select School Board Type</option>
//                   <option value="CBSE">CBSE</option>
//                   <option value="ICSE">ICSE</option>
//                   <option value="IB">IB</option>
//                 </select>
//               </label>
//             </div>
//           </div>

//           <div className='row'>
//             <div className='col-12 col-sm-8 col-md-6 col-lg-6'>
//               <div className="radio-count">
//                 <label>
//                   Have you ever been convicted of any crime involving actions contrary to the community standard of justice, honesty, or good morals?
//                   <div>
//                     <label>
//                       <input
//                         type="radio"
//                         {...register("crime_information_type")}
//                         value="yes"
//                       />
//                       Yes
//                     </label>
//                     <label>
//                       <input
//                         type="radio"
//                         {...register("crime_information_type")}
//                         value="no"
//                       />
//                       No
//                     </label>
//                   </div>
//                 </label>
//               </div>

//               {getValues("crime_information_type") === "yes" && (
//                 <div className="radio-count">
//                   <label>
//                     If yes, please state nature and status:
//                     <textarea
//                       {...register("crime_information_details")}
//                       className='inp'
//                     />
//                   </label>
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="radio-count">
//             <label>
//               Is there any pending lawsuit, whether civil or criminal, of which you are accused?
//               <div>
//                 <label>
//                   <input 
//                     type="radio"
//                     {...register("pending_court_case_type")}
//                     value="yes"
//                   />
//                   Yes
//                 </label>
//                 <label>
//                   <input 
//                     type="radio"
//                     {...register("pending_court_case_type")}
//                     value="no"
//                   />
//                   No
//                 </label>
//               </div>
//             </label>

//             {getValues("pending_court_case_type") === "yes" && (
//               <label>
//                 Please provide details of the pending court case:
//                 <textarea
//                   {...register("pending_court_case_details")}
//                   className='inp'
//                 />
//               </label>
//             )}
//           </div>

//           <div className="radio-count">
//             <label>
//               Is there any land dispute or any legal complications in the possession of the school land?
//               <div>
//                 <label>
//                   <input 
//                     type="radio"
//                     {...register("land_possession_type")}
//                     value="yes"
//                   />
//                   Yes
//                 </label>
//                 <label>
//                   <input 
//                     type="radio"
//                     {...register("land_possession_type")}
//                     value="no"
//                   />
//                   No
//                 </label>
//               </div>
//             </label>

//             {getValues("land_possession_type") === "yes" && (
//               <label>
//                 If yes, please provide details:
//                 <textarea
//                   {...register("land_possession_details")}
//                   className='inp'
//                 />
//               </label>
//             )}
//           </div>

//           <div className="mt-4 text-center">
//             <ReCaptcha siteKey="6LdWE0MqAAAAAPEFH02nZte7cGR3pw3GHufoIhU0" />
//           </div>

//           <button className='previous action-button-previous'
//             type="button"
//             onClick={prevHook}
//           >
//             Previous
//           </button>

//           <button className='next action-button'
//             type="submit"
//           >
//             Submit
//           </button>
//         </fieldset>
//       </form>
//     </div>
//   );
// }
